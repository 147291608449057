<template>
  <a-row class="search-params-margin">
    <a-col :span="labelCol">
      <div class="label-title">{{title}}</div>
    </a-col>
    <a-col :span="valueCol">
      <a-date-picker style="width: 100%" :picker="picker" v-model:value="inputValue" @change="onChange" allowClear :disabled="disabled" :showTime="showTime" :format="formatStr" />
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Object,
      default: () => {}
    },

    title: {
      type: String,
      default: '标题'
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    disabled: {
      type: Boolean,
      default: false
    },

    showTime: {
      type: Boolean,
      default: false
    },
    picker: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    }
  },
  setup (props, { emit }) {
    const inputValue = ref(props.value)
    const formatStr = ref(props.format)

    watch(props, e => {
      inputValue.value = e.value

      if (!e.showTime) {
        formatStr.value = 'YYYY-MM-DD'
      } else {
        formatStr.value = props.format
      }
    })

    const onChange = e => {
      emit('update:value', e)
    }

    return {
      inputValue,
      formatStr,
      onChange
    }
  }
})
</script>

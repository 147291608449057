<template>
  <a-row>
    <a-col :span="labelCol">
      <div class="label-title">{{title}}</div>
    </a-col>
    <a-col :span="valueCol">
      <div class="value" :style="valueStyle">
        <a-tag v-for="(v,k) in inputValue" :key="k">{{v}}</a-tag>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Array,
      default: () => []
    },

    title: {
      type: String,
      default: '标题'
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    valueStyle: String
  },
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, e => {
      inputValue.value = e.value
    })

    return {
      inputValue
    }
  }
})
</script>

<style lang="less" scoped>
  .must{
    position: absolute;
    top: 0;
    right: -10px;
    color: #f00;
    font-size: 18px;
    line-height: 32px;
  }
  .value{
    line-height: 32px;
  }
</style>

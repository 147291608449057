
import moment from 'moment'
import { createTextArea, formatDecimal } from '@/utils'
import select from 'select'
import { message } from 'ant-design-vue'
import store from '../store'

export default {
  checkImage (value) {
    return value || '/logo.png'
  },

  image (img) {
    return img || store.state.user.storeInfo.logo
  },

  formatNum (num, unit = '条') {
    let html = ''
    if (num > 10000) {
      html = (num / 10000).toFixed(2) + '<span style="font-size:12px">万' + unit + '</span>'
    } else {
      html = num + '<span style="font-size:12px">' + unit + '</span>'
    }
    return html
  },

  formatDate (str, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!str) return ''
    return moment(str).format(format)
  },

  nowformat (format = 'YYYY-MM-DD HH:mm:ss') {
    return moment().format(format)
  },

  getLabel (options, value) {
    const data = options.filter((e) => e.value === value)
    return data[0].label
  },

  unitLabel (unit) {
    const units = store.state.app.unit
    const data = units.filter((e) => e.value === unit)
    return data[0].label
  },

  tableLabel (state) {
    const units = store.state.app.table
    const data = units.filter((e) => e.value === state)
    return data[0].label
  },

  formatExt (str) {
    const arr = str.split('.')
    return arr[arr.length - 1]
  },

  formatDecimal (num, decimal = 2) {
    return formatDecimal(num, decimal)
  },

  firstName (name) {
    return name ? name.split('')[0] : '未知'
  },

  formatSize (num, unit = 'MB') {
    let denominator
    switch (unit) {
      case 'B':
        denominator = 1
        break
      case 'KB':
        denominator = 1024
        break
      case 'MB':
        denominator = 1024 * 1024
        break
      case 'GB':
        denominator = 1024 * 1024 * 1024
        break
      default:
        denominator = 1024 * 1024
        break
    }

    return formatDecimal(num / denominator, 2) + unit
  },

  copy (text) {
    const area = createTextArea(text)
    document.body.appendChild(area)
    select(area)
    let succeeded

    try {
      succeeded = document.execCommand('copy')
    } catch (err) {
      succeeded = false
    }

    if (succeeded && text) {
      message.success(`复制${text}成功`)
    } else {
      message.error('复制失败')
    }

    document.body.removeChild(area)
  },

  clearHx (str, hx = '-') {
    return str.split(hx).join('')
  }
}

<template>
  <a-row>
    <a-col :span="labelCol">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="valueCol">
      <a-form-item v-bind="validate" :extra="extra">
        <a-tree-select
          v-model:value="inputValue"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="options"
          :field-names="fieldNames"
          :placeholder="'选择' + title"
          tree-default-expand-all
          :disabled="disabled"
          allowClear
          @change="onChange"
        >
        </a-tree-select>
        <span v-if="validate" class="must">*</span>
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: [String, Number]
    },

    title: {
      type: String,
      default: '标题'
    },

    extra: {
      type: String,
      default: ''
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    validate: {
      type: Object,
      default: () => null
    },

    options: {
      type: Array,
      default: () => []
    },

    fieldNames: {
      type: Object,
      default: () => {}
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)
    watch(props, (e) => {
      inputValue.value = e.value
    })

    console.log(props.options)

    const onChange = (e) => {
      emit('ok', e)
      emit('update:value', e)
    }

    return {
      inputValue,
      onChange
    }
  }
})
</script>

<style lang="less" scoped>
.must {
  position: absolute;
  top: 0;
  right: -10px;
  color: #f00;
  font-size: 18px;
  line-height: 32px;
}
</style>

<template>
  <div :class="prefixCls">
    <div ref="editor" class="editor-wrapper"></div>

    <Resource ref="resource" @ok="emitChooseResource" />
    <Resource2 ref="resource2" :projectId="2" @ok="emitChooseResource2" />
  </div>
</template>

<script>
import WEditor from 'wangeditor'
import Resource from '@/components/Resource'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WangEditor',
  props: {
    prefixCls: {
      type: String,
      default: 'ant-editor-wang'
    },
    // eslint-disable-next-line
    modelValue: {
      type: String
    }
  },
  components: {
    Resource,
    Resource2: Resource
  },
  data () {
    return {
      editor: null
    }
  },
  mounted () {
    this.initEditor()
  },
  methods: {
    setContent (e) {
      this.editor.txt.html(e)
    },
    initEditor () {
      this.editor = new WEditor(this.$refs.editor)

      this.editor.config.onchange = html => {
        this.$emit('change', html)
        this.$emit('update:modelValue', html)
      }

      this.editor.config.uploadVideoServer = '/api/upload-video'
      this.editor.config.showLinkVideo = false
      this.editor.config.showLinkImg = false

      this.editor.config.uploadImgFromMedia = () => {
        this.$refs.resource.open()
      }

      this.editor.config.uploadVideoFromMedia = () => {
        this.$refs.resource2.open()
      }

      this.editor.create()
    },

    emitChooseResource (e) {
      const image = e.images
      image.forEach(x => {
        if (x.indexOf('.jpg') > 0 || x.indexOf('.png') > 0 || x.indexOf('.gif') > 0 || x.indexOf('.jpeg') > 0 || x.indexOf('.bmp') > 0) {
          this.editor.cmd.do('insertHTML', `<img src="${x}"/>`)
        } else {
          this.editor.cmd.do('insertHTML', `<a href="${x}" target="_blank">点击菜单栏“链接”按钮修改文本</a>`)
        }
      })
    },

    emitChooseResource2 (e) {
      const image = e.images
      image.forEach(x => {
        if (x.indexOf('.mp4') > 0 || x.indexOf('.avi') > 0 || x.indexOf('.wmv') > 0 || x.indexOf('.flv') > 0 || x.indexOf('.mkv') > 0) {
          this.editor.cmd.do('insertHTML', `<video src="${x}" controls="controls" style="max-width:100%"></video>`)
        } else {
          this.editor.cmd.do('insertHTML', `<a href="${x}" target="_blank">点击菜单栏“链接”按钮修改文本</a>`)
        }
      })
    }
  }
})
</script>

<style lang="less" scoped>
.ant-editor-wang {
  .editor-wrapper {
    text-align: left;
  }
}
</style>

import { MenuClass } from '@/apis/menu'
import { RoleClass } from '@/apis/role'

const role = new RoleClass()
const menu = new MenuClass()

const data = {
  state: {
    ALL_MENUS: [],
    ALL_RULES: [],
    ALL_ROLES: [],
    ALL_ARTICLETYPES: []
  },
  mutations: {
    SET_DATA_MENUS (state, value) {
      state.ALL_MENUS = value
    },
    SET_DATA_ROLES (state, value) {
      state.ALL_ROLES = value
    },
    SET_DATA_RULES (state, value) {
      state.ALL_RULES = value
    },

    SET_DATA_ARTICLETYPES (state, value) {
      state.ALL_ARTICLETYPES = value
    }
  },
  actions: {
    // 权限菜单
    GetMenuData ({ commit }, roleId) {
      return new Promise((resolve, reject) => {
        menu.gets({ roleId, Limit: 99999 }).then(resp => {
          commit('SET_DATA_MENUS', resp.data)
          resolve(resp.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    // 全部角色
    GetAllRoles ({ commit }) {
      return new Promise((resolve, reject) => {
        role.all().then(resp => {
          commit('SET_DATA_ROLES', resp)
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取角色失败 !'))
        })
      })
    },

    // 全部权限
    GetAllRules ({ commit }) {
      return new Promise((resolve, reject) => {
        menu.gets({ Limit: 99999 }).then(resp => {
          commit('SET_DATA_RULES', resp.data)
          resolve(resp.data)
        }).catch(() => {
          reject(new Error('获取权限失败 !'))
        })
      })
    }

  }
}

export default data

<template>
  <div class="upload">
    <t-upload
      v-model="inputValue"
      :multiple="false"
      :max="1"
      :requestMethod="onUpload"
      class="upload-btn"
    >
    </t-upload>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { Upload as TUpload } from 'tdesign-mobile-vue'
import { RcClass } from '@/apis/rc'
const rc = new RcClass()
export default defineComponent({
  components: {
    TUpload
  },
  props: {
    value: {
      type: String
    },
    title: {
      type: String
    }
  },
  setup (props, { emit }) {
    const inputValue = ref([])

    if (props.value) {
      inputValue.value = [{
        url: props.value
      }]
    }

    watch(props, (e) => {
      if (e.value) {
        inputValue.value = [{
          url: e.value
        }]
      }
    })

    const onUpload = (e) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', e.raw, e.name)

        rc.add(formData)
          .then((resp) => {
            emit('update:value', resp.url)

            resolve({
              status: 'success',
              response: { url: resp.url }
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    return {
      inputValue,

      onUpload
    }
  }
})
</script>

<style lang="less" scoped>
.upload-btn {
  display: block;
  width: 80px;
  height: 80px;
  overflow: hidden;
  padding: 0;
  background-color: #f3f3f3;
  border-radius: 6px;
}
.upload{
  width: 80px;
  .title{
    text-align: center;
    font-size: 14px;
    line-height: 32px;
  }
}
</style>

<template>
  <div class="menus">
    <a-menu
      v-model:selectedKeys="selectedKeys"
      v-model:openKeys="openKeys"
      mode="inline"
      theme="dark"
      @select="handleSelect"
    >
      <template v-for="v in list" :key="v.name">
        <template v-if="!v.children || v.hideChildren">
          <a-menu-item :key="v.name" @click="navigate(v.path)">
            <template #icon>
              <icon-font :type="v.meta.icon" />
            </template>
            {{ v.meta.title }}
          </a-menu-item>
        </template>
        <template v-else>
          <sub-menu :menu-info="v" :key="v.name" />
        </template>
      </template>
    </a-menu>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue/dist/vue.esm-bundler.js'
import { createFromIconfontCN } from '@ant-design/icons-vue'
import store from '@/store'
import { hasHiddenChildren } from '@/config/router.config'

const IconFont = createFromIconfontCN({
  scriptUrl: '/icon-font.js'
})

const SubMenu = {
  name: 'SubMenu',
  props: {
    menuInfo: {
      type: Object,
      default: () => ({})
    }
  },
  template: `
    <a-sub-menu :key="menuInfo.name">
      <template #icon><icon-font :type="menuInfo.meta.icon" /></template>
      <template #title>{{ menuInfo.meta.title }}</template>
      <template v-for="v in menuInfo.children" :key="v.name">
        <template v-if="!v.children || v.hideChildren">
          <a-menu-item :key="v.name">
            <router-link :to="v.path">
              {{ v.meta.title }}
            </router-link>
          </a-menu-item>
        </template>
        <template v-else>
          <sub-menu :menu-info="v" :key="v.name" />
        </template>
      </template>
    </a-sub-menu>
  `,
  components: {
    IconFont
  }
}

export default defineComponent({
  setup () {
    const collapsed = ref(false)
    const selectedKeys = ref([])
    const openKeys = ref([])

    const list = store.state.permission.addRouters[0].children

    const handleSelect = e => {
      console.log(e.key)
    }

    return {
      list,
      collapsed,
      selectedKeys,
      openKeys,
      handleSelect
    }
  },

  components: {
    'sub-menu': SubMenu,
    IconFont
  },

  created () {
    // 监听路由变化，更新页面路由信息
    this.$watch(
      () => this.$route,
      () => {
        this.updateRoute()
      },
      { immediate: true }
    )
  },
  methods: {
    updateRoute () {
      const { name, matched } = this.$route
      this.openKeys = []

      this.selectedKeys = [name]

      const openKeys = []
      matched.filter(e => e.name !== name).forEach(e => {
        openKeys.push(e.name)
      })
      this.openKeys = openKeys
      // 判断是否包含隐藏的子页面
      if (openKeys.filter(e => hasHiddenChildren.includes(e)).length > 0) {
        this.selectedKeys = openKeys
      }
    },

    navigate (path) {
      this.$router.push({ path })
    }
  }
})
</script>

<style lang="less" scoped>
.menus{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 0;
  &::-webkit-scrollbar{
    width: 0;
  }
}
</style>

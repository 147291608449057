<template>
  <div class="mobile-content">
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    return {}
  },
  created () {
    let size = document.documentElement.clientWidth / 7.5
    if (size > 90) {
      size = 90
    }

    console.log(size)
    document.documentElement.style.fontSize = size + 'px'
  },
  methods: {}
})
</script>

<style lang="less" scoped>
.mobile-content {
  background: url(../assets/wechart/word.jpg) no-repeat top center;
  background-size: 100% 100%;
  min-height: 100vh;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 初始化一下参数，存放到vuex中
import './core/bootstrap'
// 过滤器
import filters from './utils/filters'
// 前置路由处理，可扩展权限菜单判断
import './permission'
// ant-design
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'

import { Cascader as TCascader, Badge as TBadge } from 'tdesign-mobile-vue'
import 'tdesign-mobile-vue/es/style/index.css'

// 拖拽组件
import { plugin as Slicksort } from 'vue-slicksort'
// 全局组件
import EModal from '@/components/Modal.vue'
import EMModal from '@/components/MModal.vue'
import EIconFont from '@/components/IconFont'

import EInput from '@/components/form/Input'
import ESelect from '@/components/form/Select'
import EInputNumber from '@/components/form/InputNumber'
import EInputUpload from '@/components/form/InputUpload'
import ECascader from '@/components/form/Cascader'
import ETextarea from '@/components/form/Textarea'
import ERadio from '@/components/form/Radio'
import ESwitch from '@/components/form/Switch'
import EInfo from '@/components/form/Info'
import EShow from '@/components/form/Show'
import ETag from '@/components/form/Tag'
import ETreeSelect from '@/components/form/TreeSelect'
import EDateTime from '@/components/form/DateTime'
import EEditor from '@/components/form/Editor'
import ECheckBox from '@/components/form/CheckBox'
import EChooseImages from '@/components/form/ChooseImages'

import SInput from '@/components/search-form/Input'
import SSelect from '@/components/search-form/Select'
import SInputNumber from '@/components/search-form/InputNumber'
import SCascader from '@/components/search-form/Cascader'
import SRadio from '@/components/search-form/Radio'
import SSwitch from '@/components/search-form/Switch'
import STreeSelect from '@/components/search-form/TreeSelect'
import SDateTime from '@/components/search-form/DateTime'

// 手机端上传图片
import WUpload from '@/components/wechart/Upload'

const app = createApp(App)

// filter的替换品。用法：$filters.checkImage(参数)
app.config.globalProperties.$filters = filters
// 全局组件
app.component('e-modal', EModal)
app.component('e-modal-m', EMModal)
app.component('icon-font', EIconFont)

app.component('e-input', EInput)
app.component('e-select', ESelect)
app.component('e-input-number', EInputNumber)
app.component('e-input-upload', EInputUpload)
app.component('e-cascader', ECascader)
app.component('e-textarea', ETextarea)
app.component('e-radio', ERadio)
app.component('e-switch', ESwitch)
app.component('e-info', EInfo)
app.component('e-show', EShow)
app.component('e-tag', ETag)
app.component('e-tree-select', ETreeSelect)
app.component('e-date-time', EDateTime)
app.component('e-editor', EEditor)
app.component('e-checkbox', ECheckBox)
app.component('e-choose-images', EChooseImages)

app.component('s-input', SInput)
app.component('s-select', SSelect)
app.component('s-input-number', SInputNumber)
app.component('s-cascader', SCascader)
app.component('s-radio', SRadio)
app.component('s-switch', SSwitch)
app.component('s-tree-select', STreeSelect)
app.component('s-date-time', SDateTime)

app.component('w-upload', WUpload)

// 全局使用 ant-design-vue 插件
app.use(Antd)
// 全局使用 Slicksort拖拽组件
app.use(Slicksort)

app.use(TCascader)
app.use(TBadge)

// 注册store，router，挂载
app.use(store).use(router).mount('#app')

<template>
  <div class="drag-able" ref="dragAble">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { addEvent, prEvent, removeEvent, addWheelEvent } from '@/utils'

export default defineComponent({
  setup () {
    return {}
  },

  mounted () {
    this.$nextTick(() => {
      this.drag()
      this.wheel()
    })
  },

  methods: {
    drag () {
      const drag = this.$refs.dragAble

      addEvent(drag, 'mousedown', function (ev) {
        const oEvent = prEvent(ev)
        const oParent = drag.parentNode

        const disX = oEvent.clientX - drag.offsetLeft
        const disY = oEvent.clientY - drag.offsetTop
        const startMove = function (ev) {
          if (oParent.setCapture) {
            oParent.setCapture()
          }
          const oEvent = ev || window.event
          const L = oEvent.clientX - disX
          const T = oEvent.clientY - disY

          drag.style.left = L + 'px'
          drag.style.top = T + 'px'

          oParent.onselectstart = function () {
            return false
          }
        }
        const endMove = function () {
          if (oParent.releaseCapture) {
            oParent.releaseCapture()
          }
          oParent.onselectstart = null

          removeEvent(oParent, 'mousemove', startMove)
          removeEvent(oParent, 'mouseup', endMove)
          removeEvent(window, 'mouseup', endMove)
        }
        addEvent(oParent, 'mousemove', startMove)
        addEvent(oParent, 'mouseup', endMove)
        addEvent(window, 'mouseup', endMove)
        return false
      })
    },

    wheel () {
      const drag = this.$refs.dragAble
      const _this = this;
      (function () {
        addWheelEvent(drag, function (delta) {
          const dwidth = document.body.clientWidth
          const dheight = document.body.clientHeight
          const ratioL =
              (this.clientX - (dwidth - 700) / 2 - drag.offsetLeft) /
              drag.offsetWidth
          const ratioT =
              (this.clientY - (dheight - 700) / 2 - drag.offsetTop) /
              drag.offsetHeight
          const ratioDelta = !delta ? 1 + 0.1 : 1 - 0.1
          const w = parseInt(drag.offsetWidth * ratioDelta)
          const h = parseInt(drag.offsetHeight * ratioDelta)
          const l = Math.round(this.clientX - (dwidth - 700) / 2 - w * ratioL)
          const t = Math.round(this.clientY - (dheight - 700) / 2 - h * ratioT)

          drag.style.width = w + 'px'
          drag.style.height = h + 'px'
          drag.style.left = l + 'px'
          drag.style.top = t + 'px'

          _this.$emit('resize', { w, h, l, t })
        })
      })()
    }
  }
})
</script>

<style lang="less" scoped>
.drag-able {
  position: absolute;
  cursor: move;
}
</style>

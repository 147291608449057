import axios from 'axios'
import defaultConfig from '@/config/default.config'
import storage from 'store'
import store from '../store'
import router from '../router'
import { notification } from 'ant-design-vue'
import { ACCESS_TOKEN, ACCESS_TOKEN_SIMPLE } from '@/store/mutation-types'
import qs from 'qs'

const http = axios.create({
  // API 请求的默认前缀
  baseURL: defaultConfig.api_host,
  timeout: 600000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  console.log(error)
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 500) {
      notification.error({
        message: '服务器错误',
        description: data.title
      })
    }
    if (error.response.status === 403) {
      notification.error({
        message: '请求被拒绝',
        description: data.title
      })
    }
    if (error.response.status === 400) {
      notification.error({
        message: '警告',
        description: data.title
      })
    }
    if (error.response.status === 401) {
      storage.remove(ACCESS_TOKEN)
      storage.remove(ACCESS_TOKEN_SIMPLE)
      store.commit('SET_MENUS', [])
      store.commit('SET_TOKEN', '')
      store.commit('SET_TOKEN_SIMPLE', '')
      store.commit('SET_ROLE', [])

      if (location.href.indexOf('/wechart/') > 0) {
        router.push({ name: 'wechart-login' })
      } else {
        notification.error({
          message: '登录超时',
          description: '登录授权已过期，请重新登录'
        })

        router.push({ name: 'manage-login' })
      }
    }
  }
  return Promise.reject(error.response)
}

http.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers.Authorization = token
  }
  config.paramsSerializer = function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
  return config
}, errorHandler)

// 处理状态码204请求
http.interceptors.response.use((response) => {
  if (response.status === 204) {
    return null
  }
  return response.data
}, errorHandler)

export default http

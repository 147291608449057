<template>
  <div>
    <div class="cover" ref="cover" v-show="visible" @click="onCloseCover">
      <div
        :class="{
          'modal-box': true,
          show: show,
        }"
        :style="{
          width: width + 'px',
          top: top + 'px',
          left: left + 'px',
        }"
        ref="dragBox"
        @click.stop="() => false"
      >
        <div class="modal-title" ref="dragAble">
          <div class="title">{{ title }}</div>
          <a @click="onClose">
            <CloseOutlined style="font-size: 16px" />
          </a>
        </div>
        <div
          class="modal-body"
          :style="{
            height: height > 0 ? height + 'px' : 'auto',
          }"
        >
          <slot></slot>
        </div>
        <div class="modal-footer" v-if="footer">
          <slot name="footer">
            <div class="btns">
              <a-button style="margin-right: 15px" @click="onClose">取消</a-button>
              <a-button
                type="primary"
                @click="onOk"
                :loading="loading"
              >
                {{ okText }}
              </a-button>

            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import { addEvent, prEvent, removeEvent } from '@/utils'

export default defineComponent({
  components: {
    CloseOutlined
  },
  props: {
    visible: Boolean,
    onlyBody: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: String,
    okText: {
      type: String,
      default: '确定'
    },
    offsetTop: Number,
    closeAble: {
      type: Boolean,
      default: true
    },
    footer: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 0
    }
  },
  setup (props, { emit }) {
    const top = ref(0)
    const left = ref(0)
    const show = ref(false)
    const full = ref(false)

    const onClose = () => {
      emit('close')
    }

    const onOk = () => {
      emit('ok')
    }

    return {
      left,
      top,
      show,
      full,
      onClose,
      onOk
    }
  },

  created () {
    this.$watch('visible', (e) => {
      if (e) {
        setTimeout(() => {
          this.setModalStyle()
        }, 10)
      }
    })
  },

  mounted () {
    this.$nextTick(() => {
      this.drag()
    })
  },

  methods: {
    onCloseCover () {
      if (this.closeAble) {
        console.log('点击遮罩')
        // this.$emit('close')
      }
    },
    setModalStyle () {
      const w = this.width
      const tW = document.body.clientWidth
      const tH = document.body.clientHeight
      const h = this.$refs.dragBox.clientHeight
      this.left = (tW - w) / 2
      const top = this.offsetTop ? this.offsetTop : (tH - h) / 2
      this.show = true

      this.top = top > 0 ? top : 0
      console.log(this.top)
    },
    drag () {
      const drag = this.$refs.dragAble
      const body = this.$refs.dragBox

      addEvent(drag, 'mousedown', function (ev) {
        const oEvent = prEvent(ev)
        const oParent = body.parentNode
        const disX = oEvent.clientX - body.offsetLeft
        const disY = oEvent.clientY - body.offsetTop
        const startMove = function (ev) {
          if (oParent.setCapture) {
            oParent.setCapture()
          }
          const oEvent = ev || window.event
          const L = oEvent.clientX - disX
          const T = oEvent.clientY - disY
          body.style.left = L + 'px'
          body.style.top = T + 'px'

          oParent.onselectstart = function () {
            return false
          }
        }
        const endMove = function () {
          if (oParent.releaseCapture) {
            oParent.releaseCapture()
          }
          oParent.onselectstart = null

          removeEvent(oParent, 'mousemove', startMove)
          removeEvent(oParent, 'mouseup', endMove)
        }
        addEvent(oParent, 'mousemove', startMove)
        addEvent(oParent, 'mouseup', endMove)
        return false
      })
    }
  }
})
</script>

<style lang="less" scoped>
.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99996;
  transition: all 0.5s ease;
}
.modal-box {
  background: #fff;
  position: absolute;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.5s ease;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-height: 716px;
  .modal-title {
    cursor: move;
    padding: 15px 20px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      word-wrap: break-word;
    }
  }

  .modal-body {
    padding: 15px 20px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
    max-height: 600px;
  }

  .modal-footer {
    border-top: 1px solid #f0f0f0;
    padding: 15px 20px;
    .btns {
      display: flex;
      justify-content: flex-end;
    }
  }

  &.show {
    opacity: 1;
  }
}
</style>

import { UserLayout, BasicLayout, RouteView, MobileLayout } from '@/layouts'

// 平台路由
const asyncRouterMap = [
  {
    path: '/manage',
    name: 'manage',
    component: BasicLayout,
    redirect: '/manage/dashboard',
    children: [
      // 数据
      {
        path: '/manage/dashboard',
        name: 'manage-dashboard',
        redirect: '/manage/dashboard/index',
        component: RouteView,
        meta: {
          title: '数据',
          keepAlive: true,
          icon: 'icon-data',
          permission: [
            'manage-dashboard-index'
          ]
        },
        children: [
          {
            path: '/manage/dashboard/index',
            name: 'manage-dashboard-index',
            component: () => import('@/views/hg-public/dashboard/Analysis'),
            meta: { title: '数据展示', keepAlive: true, permission: ['manage-dashboard-index'] }
          }
        ]
      },

      // 文章
      {
        path: '/manage/article',
        name: 'manage-article',
        redirect: '/manage/article/list',
        component: RouteView,
        meta: {
          title: '文章',
          keepAlive: true,
          icon: 'icon-16',
          permission: [
            'manage-article-list',
            'manage-article-type'
          ]
        },
        children: [
          {
            path: '/manage/article/list',
            name: 'manage-article-list',
            component: () => import('@/views/hg-platform/article/List'),
            meta: { title: '文章管理', keepAlive: true, permission: ['manage-article-list'] }
          },
          {
            path: '/manage/article/type',
            name: 'manage-article-type',
            component: () => import('@/views/hg-platform/article/Type'),
            meta: { title: '文章分类', keepAlive: true, permission: ['manage-article-type'] }
          }
        ]
      },

      // 广告
      {
        path: '/manage/ad',
        name: 'manage-ad',
        redirect: '/manage/ad/list',
        component: RouteView,
        meta: {
          title: '广告',
          keepAlive: true,
          icon: 'icon-zhongdianguanggaowei',
          permission: [
            'manage-ad-list'
          ]
        },
        children: [
          {
            path: '/manage/ad/list',
            name: 'manage-ad-list',
            component: () => import('@/views/hg-platform/ad/List'),
            meta: { title: '广告管理', keepAlive: true, permission: ['manage-ad-list'] }
          }
        ]
      },

      // 用户
      {
        path: '/manage/user',
        name: 'manage-user',
        redirect: '/manage/admin/list',
        component: RouteView,
        meta: {
          title: '用户',
          keepAlive: true,
          icon: 'icon-user',
          permission: [
            'manage-admin-list'
          ]
        },
        children: [
          {
            path: '/manage/admin/list',
            name: 'manage-admin-list',
            component: () => import('@/views/hg-public/user/List'),
            meta: { title: '用户管理', keepAlive: true, permission: ['manage-admin-list'] }
          }
        ]
      },

      // 权限
      {
        path: '/manage/auth',
        name: 'manage-auth',
        redirect: '/manage/auth/roles',
        component: RouteView,
        meta: { title: '权限', keepAlive: true, icon: 'icon-quanxian', permission: ['manage-auth-roles', 'manage-auth-rules'] },
        children: [
          {
            path: '/manage/auth/roles',
            name: 'manage-auth-roles',
            component: () => import('@/views/hg-public/auth/Roles'),
            meta: { title: '角色列表', keepAlive: true, permission: ['manage-auth-roles'] }
          },
          {
            path: '/manage/auth/rules',
            name: 'manage-auth-rules',
            component: () => import('@/views/hg-public/auth/Rules'),
            meta: { title: '权限列表', keepAlive: true, permission: ['manage-auth-rules'] }
          }
        ]
      },

      {
        path: '/manage/config',
        name: 'manage-config',
        redirect: '/manage/config/index',
        component: RouteView,
        meta: { title: '系统', keepAlive: true, icon: 'icon-xitong', permission: ['manage-config-index', 'manage-config-logs'] },
        children: [
          {
            path: '/manage/config/index',
            name: 'manage-config-index',
            component: () => import('@/views/hg-public/config/Index'),
            meta: { title: '系统配置', keepAlive: true, permission: ['manage-config-index'] }
          },
          {
            path: '/manage/config/logs',
            name: 'manage-config-logs',
            component: () => import('@/views/hg-public/log/List'),
            meta: { title: '操作日志', keepAlive: true, permission: ['manage-config-logs'] }
          }
        ]
      }
    ]
  }
]

const manage = [
  {
    path: '/',
    name: 'index',
    redirect: '/manage',
    component: RouteView
  },
  {
    path: '/manage',
    name: 'manage',
    component: BasicLayout
  },
  {
    path: '/manage',
    component: UserLayout,
    redirect: '/manage/login',
    children: [
      {
        path: '/manage/login',
        name: 'manage-login',
        component: () => import('@/views/hg-public/manage/Login'),
        meta: { title: '平台管理登录' }
      }
    ]
  },
  {
    path: '/wechart',
    component: RouteView,
    redirect: '/wechart/login',
    children: [
      {
        path: '/wechart/login',
        name: 'wechart-login',
        component: () => import('@/views/hg-wechart/Login'),
        meta: { title: '登录' }
      }
    ]
  }
]

const constantRouterMap = [
  {
    path: '/account',
    name: 'account',
    component: BasicLayout,
    redirect: '/account/index',
    children: [
      {
        path: '/account/index',
        name: 'account-index',
        redirect: '/account/info',
        component: () => import('@/views/hg-public/account/Index'),
        meta: { title: '账号管理', keepAlive: true },
        children: [
          {
            path: '/account/info',
            name: 'account-info',
            component: () => import('@/views/hg-public/account/Info'),
            meta: { title: '我的信息', keepAlive: true }
          }
        ]
      }
    ]
  },
  {
    path: '/wechart',
    name: 'wechart',
    component: MobileLayout,
    children: [
      {
        path: '/wechart/user',
        name: 'wechart-user',
        component: () => import('@/views/hg-wechart/User'),
        meta: { title: '用户中心', keepAlive: true }
      },
      {
        path: '/wechart/info',
        name: 'wechart-info',
        component: () => import('@/views/hg-wechart/Info'),
        meta: { title: '个人信息', keepAlive: true }
      },
      {
        path: '/wechart/health-record',
        name: 'wechart-health-record',
        component: () => import('@/views/hg-wechart/HealthRecord'),
        meta: { title: '健康档案', keepAlive: true }
      },
      {
        path: '/wechart/regist',
        name: 'wechart-regist',
        component: () => import('@/views/hg-wechart/Regist'),
        meta: { title: '注册', keepAlive: true }
      },
      {
        path: '/wechart/index',
        name: 'wechart-index',
        component: () => import('@/views/hg-wechart/Index'),
        meta: { title: '首页', keepAlive: true }
      },
      {
        path: '/wechart/reports',
        name: 'wechart-reports',
        component: () => import('@/views/hg-wechart/Reports'),
        meta: { title: '报告列表', keepAlive: true }
      },
      {
        path: '/wechart/report',
        name: 'wechart-report',
        component: () => import('@/views/hg-wechart/Report'),
        meta: { title: '报告详情', keepAlive: true }
      },
      {
        path: '/wechart/category',
        name: 'wechart-category',
        component: () => import('@/views/hg-wechart/Category'),
        meta: { title: '项目列表', keepAlive: true }
      },
      {
        path: '/wechart/detail',
        name: 'wechart-detail',
        component: () => import('@/views/hg-wechart/Detail'),
        meta: { title: '项目详情', keepAlive: true }
      },
      {
        path: '/wechart/zhibiao',
        name: 'wechart-zhibiao',
        component: () => import('@/views/hg-wechart/Zhibiao'),
        meta: { title: '重点检测指标', keepAlive: true }
      }
    ]
  }
]

const notfound = [
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/hg-public/exception/404')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/hg-public/exception/404')
  }
]

constantRouterMap.push(...manage)
constantRouterMap.push(...notfound)

const hasHiddenChildren = ['warehouse-plan-index', 'chef-plan-index', 'chef-dish-index', 'chef-combo-index', 'chef-order-index', 'hotel-dish-index', 'hotel-combo-index']

export {
  constantRouterMap,
  asyncRouterMap,
  hasHiddenChildren,
  notfound
}

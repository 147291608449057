<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>

import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { defineComponent } from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')

export default defineComponent({
  setup () {
    return {
      locale: zhCN
    }
  }
})
</script>

<style lang="less">
@import "./base_pc_style.less";
</style>

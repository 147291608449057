<template>
  <a-row>
    <a-col :span="labelCol">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="valueCol">
      <a-form-item v-bind="validate">
        <div class="images">
          <div class="item" v-for="(v, k) in images" :key="k">
            <div class="image">
              <img :src="v" :alt="'img_' + (k + 1)" />
              <div class="actions">
                <a-tooltip>
                  <template #title>查看</template>
                  <a @click.stop="view(v)">
                    <fund-view-outlined />
                  </a>
                </a-tooltip>
                <a-divider type="vertical" />
                <a-tooltip>
                  <template #title>删除</template>
                  <a @click.stop="del(k)">
                    <DeleteOutlined />
                  </a>
                </a-tooltip>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="image choose" @click="$refs.resource.open()">
              <picture-outlined style="font-size: 30px" />
              <p>选择图片</p>
            </div>
          </div>
        </div>
        <span v-if="validate" class="must">*</span>
      </a-form-item>
    </a-col>
  </a-row>
  <Resource ref="resource" :orderId="orderId" @ok="emitChooseResource" />
  <ViewImage ref="viewImage" />
</template>

<script>
import { defineComponent, ref, toRaw, watch } from 'vue'
import {
  DeleteOutlined,
  FundViewOutlined,
  PictureOutlined
} from '@ant-design/icons-vue'
import Resource from '@/components/Resource'
import ViewImage from '@/components/ViewImage'

export default defineComponent({
  props: {
    value: {
      type: [String, Array]
    },

    title: {
      type: String,
      default: '标题'
    },

    orderId: {
      type: String,
      default: ''
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    validate: {
      type: Object,
      default: () => null
    }
  },
  components: {
    Resource,
    FundViewOutlined,
    DeleteOutlined,
    PictureOutlined,
    ViewImage
  },
  setup (props, { emit }) {
    const images = ref(props.value)

    watch(props, (e) => {
      images.value = e.value
    })

    const checkImg = (url) => {
      const a = url.split('.')
      return ['jpg', 'png', 'gif'].includes(a[a.length - 1])
    }

    const emitChooseResource = (e) => {
      const list = e.images.filter(x => checkImg(x))
      images.value.push(...list)
      emit('update:value', toRaw(images.value))
    }

    const del = (index) => {
      images.value.splice(index, 1)
    }

    return {
      images,
      del,
      emitChooseResource
    }
  },

  methods: {
    view (url) {
      this.$refs.viewImage.open(url)
    }
  }
})
</script>

<style lang="less" scoped>
.must {
  position: absolute;
  top: 0;
  right: -10px;
  color: #f00;
  font-size: 18px;
  line-height: 32px;
}

.images{
  display: flex;
  flex-wrap: wrap;
  .item{
    width: 100px;
    height: 100px;

    padding: 5px;

    .image{
      border: 1px solid rgb(124, 141, 236);
      border-radius: 4px;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;

      &.choose{

        flex-direction: column;
        color: #999;
        font-size: 12px;
        cursor: pointer;
      }
      img{
        width: 100%;
      }
      p{
        margin: 0;
      }
      .actions{
        position: absolute;
        width: 100%;
        height: 30px;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all ease .3s;
        a{
          color: #fff;
          font-size: 16px;
        }
      }
      &:hover{
        .actions{
          opacity: 1;
        }
      }
    }
  }
}
</style>

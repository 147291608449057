const form = {
  data () {
    return {
      layout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 17 }
        }
      }
    }
  },

  methods: {
    onSubmit (callFun) {
      this.loading = true
      this.validate()
        .then(() => {
          // 回调处理提交
          typeof callFun === 'function' && callFun(this.modelRef)
        })
        .catch(err => {
          this.loading = false
          this.$message.error('输入错误')
          console.log('error', err)
        })
    },

    onNav (name) {
      this.$router.push({ name })
    }
  }
}

export default form

<template>
  <e-modal
    :visible="visible"
    title="上传资源管理"
    :width="1000"
    @close="onClose"
    @ok="onSubmit"
  >
    <template #footer>
      <div class="buttons">
        <div>
          <a-input
            v-model:value="queryParams.Name"
            placeholder="文件名"
            allowClear
            style="width: 150px; margin-right: 8px"
          />

          <a-date-picker
            v-model:value="queryParams.Date"
            placeholder="上传日期"
          />
          <a-button type="primary" @click="getList()"> 搜索 </a-button>

          <a-button type="primary" @click="$refs.cropperImage.open()">
            <ScissorOutlined /> 裁剪上传
          </a-button>

          <a-upload
            name="file"
            :customRequest="uploads"
            multiple
            :beforeUpload="beforeUpload"
            :showUploadList="false"
            style="margin-right: 8px"
          >
            <a-button type="primary"> <UploadOutlined /> 文件上传 </a-button>
          </a-upload>

          <a-button type="primary" @click="$refs.shotImage.open()">
            <ScissorOutlined /> 截图上传
          </a-button>
        </div>

        <div>
          <a-button
            type="primary"
            :disabled="choosed.length === 0"
            @click="onSubmit"
          >
            选择
          </a-button>
          <a-button @click="onClose">关闭</a-button>
        </div>
      </div>
    </template>
    <div class="resource-body">
      <div class="right" :style="page.total > queryParams.Limit ? 'padding-bottom: 50px': 'padding-bottom: 0'">
        <div class="rc-list">
          <a-spin tip="加载中..." :spinning="loading">
            <div class="list">
              <div
                v-for="(v, k) in list"
                :key="k"
                :class="{
                  item: true,
                  on: onCheckChoose(v.url),
                }"
                @click="onChoose(v.url)"
                @dblclick="onCropper(v)"
              >
                <div class="li">
                  <div class="li-img">
                    <img v-if="v.ext.indexOf('ico') !== -1" :src="v.url" :alt="v.title" />
                    <img v-if="v.ext.indexOf('jpg') !== -1" :src="v.url" :alt="v.title" />
                    <img v-if="v.ext.indexOf('png') !== -1" :src="v.url" :alt="v.title" />
                    <img v-if="v.ext.indexOf('gif') !== -1" :src="v.url" :alt="v.title" />
                    <img v-if="v.ext.indexOf('jfif') !== -1" :src="v.url" :alt="v.title" />
                    <img v-if="v.ext.indexOf('video') !== -1" src="~@/assets/video.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('wav') !== -1" src="~@/assets/audio.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('mp3') !== -1" src="~@/assets/audio.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('mp4') !== -1" src="~@/assets/video.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('wmv') !== -1" src="~@/assets/video.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('avi') !== -1" src="~@/assets/video.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('doc') !== -1" src="~@/assets/file.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('pdf') !== -1" src="~@/assets/file.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('xls') !== -1" src="~@/assets/file.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('ppt') !== -1" src="~@/assets/file.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('txt') !== -1" src="~@/assets/file.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('svg') !== -1" src="~@/assets/file.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('zip') !== -1" src="~@/assets/zip.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('rar') !== -1" src="~@/assets/zip.png" :alt="v.title" />
                    <img v-if="v.ext.indexOf('7z') !== -1" src="~@/assets/zip.png" :alt="v.title" />
                  </div>
                  <div class="li-title">{{ v.name }}</div>
                </div>
              </div>
            </div>
          </a-spin>
        </div>

        <div class="page" v-if="page.total > queryParams.Limit">
          <a-pagination
            :total="page.total"
            v-model:current="page.page"
            v-model:page-size="queryParams.Limit"
            :hide-on-single-page="true"
            :show-size-changer="true"
            :page-size-options="['20', '40', '100', '200']"
            @change="onChangePage"
            @showSizeChange="onSizeChange"
          >
            <template #itemRender="{ page, type }">
              <a v-if="type === 'prev'" class="ant-pagination-item-link">
                上一页
              </a>
              <a v-else-if="type === 'next'" class="ant-pagination-item-link">
                下一页
              </a>
              <span v-else>{{ page }}</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </div>

    <CropperImage ref="cropperImage" @ok="uploadCropImage" />
    <CropperOnlineImage ref="cropperOnlineImage" @ok="uploadCropImage" />
    <ShotImage ref="shotImage" @ok="uploadCropImage" />
  </e-modal>
</template>

<script>
import { defineComponent, ref, toRaw } from 'vue'
import { UploadOutlined, ScissorOutlined } from '@ant-design/icons-vue'

import { RcClass } from '@/apis/rc'
import CropperImage from './CropperImage'
import CropperOnlineImage from './CropperOnlineImage'
import ShotImage from './ShotImage'

const api = new RcClass()
export default defineComponent({
  emits: ['ok'],
  components: {
    UploadOutlined,
    ScissorOutlined,
    CropperImage,
    CropperOnlineImage,
    ShotImage
  },
  props: {
    orderId: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)

    const image = ref('')
    const number = ref(5)
    const field = ref('')
    const list = ref([])
    const choosed = ref([])

    const replaceFields = {
      key: 'id',
      title: 'name'
    }
    const page = ref({
      total: 0,
      page: 1
    })

    const queryParams = ref({
      Limit: 36
    })

    const onClose = () => {
      choosed.value = []
      visible.value = false
    }

    const onChoose = (image) => {
      const s = new Set(choosed.value)
      if (s.has(image)) {
        s.delete(image)
      } else {
        s.add(image)
      }
      choosed.value = Array.from(s)
    }

    const onCheckChoose = (image) => {
      return choosed.value.includes(image)
    }

    const getList = (index) => {
      page.value.page = index
      loading.value = true
      const q = { ...toRaw(queryParams.value) }
      q.Offset = (index - 1) * q.Limit

      if (q.Date) {
        q.Date = q.Date.format()
      }

      api.gets(q).then((resp) => {
        list.value = resp.data
        page.value.total = resp.total
        loading.value = false
      })
    }

    const onChangePage = (e) => {
      getList(e)
    }

    const onSizeChange = (cur, limit) => {
      queryParams.value.Limit = limit
      getList(cur)
    }

    return {
      visible,
      loading,
      list,
      choosed,
      image,
      page,
      queryParams,
      replaceFields,
      number,
      field,

      onClose,
      onChoose,
      onCheckChoose,
      getList,
      onChangePage,
      onSizeChange
    }
  },

  data () {
    return {
      uploadFileList: [],
      fileCount: 0
    }
  },

  methods: {
    open (number = 0, field = '') {
      this.field = field
      this.number = number
      this.getList(1)
      this.visible = true
    },

    onCropper (record) {
      if (record.ext.indexOf('jpg') > 0 || record.ext.indexOf('png') > 0 || record.ext.indexOf('gif') > 0 || record.ext.indexOf('jfif') > 0) {
        // this.$refs.cropperOnlineImage.open(record)
      }
    },

    onSubmit () {
      if (this.number > 0 && this.number < this.choosed.length) {
        this.$message.error(`最多选择${this.number}个文件`)
        return
      }
      this.$emit('ok', {
        images: toRaw(this.choosed),
        field: this.field
      })
      this.onClose()
    },

    beforeUpload (file, fileList) {
      this.fileCount = fileList.length
    },
    uploads (e) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', e.file, e.file.name)

      if (this.orderId) {
        formData.append('orderId', this.orderId)
        formData.append('useWaterMark', 1)
      }

      api.add(formData).then((resp) => {
        this.uploadFileList.push(resp.url)
        if (this.uploadFileList.length >= this.fileCount) {
          this.uploadFileList = []
          this.fileCount = 0
          this.getList(1)
        }
      })
    },

    uploadCropImage (e) {
      if (this.orderId) {
        e.append('orderId', this.orderId)
        e.append('useWaterMark', 1)
      }
      api.add(e).then(() => {
        this.$refs.cropperImage.onClose()
        this.$refs.shotImage.onClose()
        this.getList(1)
      })
    }
  }
})
</script>

<style lang="less" scoped>
.resource-body {
  position: relative;
  width: 100%;
  height: 570px;
  box-sizing: border-box;
  overflow: auto;
  .right {
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 50px;
    box-sizing: border-box;
    .rc-list {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      .list {
        width: 960px;
        display: flex;
        flex-wrap: wrap;
        .item {
          padding: 5px;
          box-sizing: border-box;
          width: 160px;
          cursor: pointer;
          .li {
            border: 1px solid transparent;
            padding: 5px;
            box-sizing: border-box;
            .li-img {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              width: 100%;
              height: 114px;

              img {
                display: block;
                max-width: 100%;
                max-height: 100%;
              }
            }

            .li-title {
              font-size: 12px;
              line-height: 24px;
              text-align: center;
              overflow: hidden;
              height: 24px;
            }
          }

          &:hover {
            .li {
              border-color: #7e70ff;
              background: url(../assets/drag-bg-1.png);
            }
          }
          &.on {
            .li {
              border-color: #4c38ff;
              background: url(../assets/drag-bg.png);
            }
          }
        }
      }
    }
    .page {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
    }
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  button {
    margin: 0 5px;
  }
}
</style>
